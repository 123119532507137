import type {
  Currencies,
  ExternalApi,
  InitiateSellResponse,
  InitiateSellWidgetRequest,
  QuoteRequest,
  TrafficProviderQuote,
  TransactionApi,
} from '../../client';

export interface IWidgetApi {
  getCurrencies: () => Promise<Currencies>;

  initiateSell: (req: InitiateSellWidgetRequest) => Promise<InitiateSellResponse>;

  quote: (req: QuoteRequest) => Promise<TrafficProviderQuote>;
}

export class SellCheckoutApi implements IWidgetApi {
  constructor(
    public transactionApi: TransactionApi,
    public external: ExternalApi,
  ) {}

  getCurrencies() {
    return this.transactionApi.getCurrencies();
  }

  initiateSell(req: InitiateSellWidgetRequest) {
    return this.external.initiateSellWidget(req);
  }

  quote(req: QuoteRequest) {
    return this.external.quote(req);
  }
}
