import { type InitialDataFunction, type QueryKey, useQuery as useVueQuery } from '@tanstack/vue-query';
import type { DeepUnwrapRef, MaybeRefDeep } from '@tanstack/vue-query/build/legacy/types';
import { computed, type MaybeRef, watch } from 'vue';
import type { IWidgetApi } from './api';
import { withApiError } from './errors';
import type { QueryResult } from './results';
import type { NonFunctionGuard, RetryValue } from './types';
import { useApi } from './use-api';

export function useQuery<T = unknown, TKey extends QueryKey = QueryKey, TError = Error>(
  key: MaybeRef<QueryKey>,
  fn: (
    api: IWidgetApi,
    args: {
      queryKey: DeepUnwrapRef<TKey>;
    },
  ) => Promise<T>,
  options?: {
    onSuccess?: (data: T) => void;
    onError?: (error: TError) => void;
    staleTime?: number;
    enabled?: MaybeRef<boolean>;
    initialData?: MaybeRefDeep<T | InitialDataFunction<T> | undefined>;
    placeholderData?: MaybeRefDeep<NonFunctionGuard<T>>;
    retry?: RetryValue<TError>;
  },
) {
  const api = useApi();

  const { data, error, status, refetch } = useVueQuery<T, TError, T, TKey>({
    queryKey: key as MaybeRefDeep<DeepUnwrapRef<TKey>>,
    queryFn: (args) => withApiError(api, args, fn),
    refetchOnWindowFocus: !import.meta.env.DEV,
    retry: options?.retry ?? !import.meta.env.DEV,
    placeholderData: options?.placeholderData,
    staleTime: options?.staleTime,
    enabled: options?.enabled,
    initialData: options?.initialData,
  });
  watch(data, (data) => data && options?.onSuccess?.(data), {
    immediate: true,
  });
  watch(error, (error) => error && options?.onError?.(error), { immediate: true });

  return [
    computed<QueryResult<T>>(() => {
      if (status.value === 'pending') {
        return {
          status: 'loading' as const,
        };
      }

      if (status.value === 'error') {
        return {
          status: 'error' as const,
          error: error.value as Error,
        };
      }

      return {
        status: 'success' as const,
        data: data.value as T,
      };
    }),
    refetch,
  ] as const;
}
