/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InitiateSellTransactionDetails } from './InitiateSellTransactionDetails';
import {
  InitiateSellTransactionDetailsFromJSON,
  InitiateSellTransactionDetailsFromJSONTyped,
  InitiateSellTransactionDetailsToJSON,
  InitiateSellTransactionDetailsToJSONTyped,
} from './InitiateSellTransactionDetails';
import type { InitiateSellAcountDetails } from './InitiateSellAcountDetails';
import {
  InitiateSellAcountDetailsFromJSON,
  InitiateSellAcountDetailsFromJSONTyped,
  InitiateSellAcountDetailsToJSON,
  InitiateSellAcountDetailsToJSONTyped,
} from './InitiateSellAcountDetails';

/**
 *
 * @export
 * @interface InitiateSell
 */
export interface InitiateSell {
  /**
   * URL-like value
   * @type {string}
   * @memberof InitiateSell
   */
  returnUrl?: string;
  /**
   * URL-like value
   * @type {string}
   * @memberof InitiateSell
   */
  deepLink?: string;
  /**
   *
   * @type {InitiateSellTransactionDetails}
   * @memberof InitiateSell
   */
  txnDetails: InitiateSellTransactionDetails;
  /**
   *
   * @type {InitiateSellAcountDetails}
   * @memberof InitiateSell
   */
  accountDetails?: InitiateSellAcountDetails;
}

/**
 * Check if a given object implements the InitiateSell interface.
 */
export function instanceOfInitiateSell(value: object): value is InitiateSell {
  if (!('txnDetails' in value) || value['txnDetails'] === undefined) return false;
  return true;
}

export function InitiateSellFromJSON(json: any): InitiateSell {
  return InitiateSellFromJSONTyped(json, false);
}

export function InitiateSellFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitiateSell {
  if (json == null) {
    return json;
  }
  return {
    returnUrl: json['return_url'] == null ? undefined : json['return_url'],
    deepLink: json['deep_link'] == null ? undefined : json['deep_link'],
    txnDetails: InitiateSellTransactionDetailsFromJSON(json['txn_details']),
    accountDetails:
      json['account_details'] == null ? undefined : InitiateSellAcountDetailsFromJSON(json['account_details']),
  };
}

export function InitiateSellToJSON(json: any): InitiateSell {
  return InitiateSellToJSONTyped(json, false);
}

export function InitiateSellToJSONTyped(value?: InitiateSell | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    return_url: value['returnUrl'],
    deep_link: value['deepLink'],
    txn_details: InitiateSellTransactionDetailsToJSON(value['txnDetails']),
    account_details: InitiateSellAcountDetailsToJSON(value['accountDetails']),
  };
}
