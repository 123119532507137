<template>
  <div class="widget">
    <div class="content-area">
      <div class="form">
        <WidgetForm
          v-if="currenciesResult.status === 'success'"
          id="form"
          :loading="loading"
          :supported-crypto-currencies="currenciesResult.data.cryptoCurrencies.map((c) => c.currencySelectOption)"
          :supported-fiat-currencies="currenciesResult.data.fiatCurrencies"
          :form-message="errorMessage"
          @submit="onSubmit"
        />
      </div>
      <div class="bottom-content">
        <PaymentMethods />
        <div class="call-to-actions">
          <SButton
            v-if="!redirectTimeout.show"
            form="form"
            class="sell-now-button"
            type="submit"
            data-testid="sell-now-button"
            :label="$t('widget.sell-button-title')"
            :disabled="
              loading ||
              !meta.valid ||
              redirectTimeout.loading ||
              quoteResult.status === 'loading' ||
              quoteResult.status === 'error'
            "
          >
            <template #iconRight><SIconActionsRightArrowNext width="16" height="16" /></template>
          </SButton>
          <SLink
            v-else
            :href="redirectUrl"
            target="_parent"
            class="s-button primary sell-now-link"
            data-testid="sell-now-link"
          >
            <SSpan class="s-button-standard-typography label margin-right"> {{ $t('widget.sell-link-title') }} </SSpan>
            <SIconActionsRightArrowNext width="16" height="16" />
          </SLink>
        </div>
        <SCaption class="support">
          <i18n-t keypath="widget.support.need-support-contact">
            <template #emailLink>
              <a :href="`mailto:${t('widget.support.email')}`" target="_blank">{{ $t('widget.support.email') }}</a>
            </template>
          </i18n-t>
        </SCaption>
      </div>
    </div>
    <div v-if="currenciesResult.status === 'error'">
      <ErrorScreen :retry="retryCurrencies" />
    </div>
    <div v-else-if="quoteResult.status === 'error' && !isHandledQuoteError(quoteResult) && errorModalOpen">
      <ErrorScreen :retry="refetchQuote" is-closeable @close-clicked="errorModalOpen = false" />
    </div>
    <div v-else-if="initiateSellResult.status === 'error'">
      <ErrorScreen :retry="retryInitiateSell" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { watchDebounced } from '@vueuse/core';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { TrafficProviderQuote } from '@simplex/idl.sell-checkout';
import { invariant } from '@simplex/rnd.invariant';
import { SButton, SCaption, SIconActionsRightArrowNext, SLink, SSpan } from '@simplex/simplex-ui';
import { ApiError } from '../app/api/errors';
import type { QueryResult } from '../app/api/results';
import { useQuery } from '../app/api/use-query';
import { useConfig } from '../app/config/use-config';
import { useTheme } from '../app/use-theme';
import ErrorScreen from '../errors/ErrorScreen.vue';
import { formatFiat, toMills, toQuoteAmount } from './amounts';
import { createCurrenciesPlaceholder, type CurrenciesConfig, mapToCurrenciesConfigs } from './currencies';
import { listenForErrorMessage, pushMessageForInitiateSell } from './messages';
import PaymentMethods from './PaymentMethods.vue';
import { parseRef } from './refs';
import { type FormValues, schema } from './schema';
import { useFieldValidation } from './use-field-validation';
import { useInitiateSell } from './use-initiate-sell';
import { useRedirectTimeout } from './use-redirect-timeout';
import WidgetForm from './WidgetForm.vue';

const { t, locale } = useI18n();
const theme = useTheme();
const config = useConfig();
const redirectTimeout = useRedirectTimeout();
const fieldValidation = useFieldValidation();
const errorMessage = ref<string>();
const redirectUrl = ref<string>();
const errorModalOpen = ref(false);

const [currenciesResult, retryCurrencies] = useQuery(
  ['currencies'],
  (api) => api.getCurrencies().then((c) => mapToCurrenciesConfigs(c)),
  {
    placeholderData: createCurrenciesPlaceholder(),
  },
);

const validationSchema = computed(() =>
  currenciesResult.value.status === 'success'
    ? toTypedSchema(schema(t, config, currenciesResult.value.data as CurrenciesConfig))
    : undefined,
);

const { handleSubmit, setFieldValue, setFieldError, values, isSubmitting, validateField, meta } = useForm<FormValues>({
  validationSchema,
  initialValues: {
    cryptoAmount: config.DEFAULTS.cryptoAmount,
    cryptoCurrency: config.DEFAULTS.cryptoCurrency,
    fiatAmount: config.DEFAULTS.fiatAmount,
    fiatCurrency: config.DEFAULTS.fiatCurrency,
  },
});

const [quoteResult, refetchQuote] = useQuery(
  [values.cryptoAmount, values.cryptoCurrency, values.fiatCurrency],
  (api) =>
    api.quote({
      baseCurrency: values.cryptoCurrency,
      quoteCurrency: values.fiatCurrency,
      baseAmount: toMills(values.cryptoAmount),
      ppPaymentMethod: config.PAYMENT_METHOD,
      ref: parseRef(window.location),
    }),
  {
    retry: false,
    onSuccess: (data) => {
      const fiatAmount =
        data.fiatAmount === undefined ? toQuoteAmount(values.cryptoAmount, data.rate) : data.fiatAmount;
      const formattedFiatAmount = formatFiat(fiatAmount, values.fiatCurrency, locale.value);
      setFieldValue('fiatAmount', formattedFiatAmount);
    },
    onError: (error: unknown) => {
      if (!(error instanceof ApiError)) {
        return;
      }

      if (error.details.code === 'amount_too_high') {
        fieldValidation.setAmountTooHightError(setFieldError, error);
      } else if (error.details.code === 'amount_too_low') {
        fieldValidation.setAmountTooLowError(setFieldError, error);
      } else if (error.details.code === 'invalid_amount') {
        fieldValidation.setAllFieldsInvalidAmountError(setFieldError);
      } else {
        errorModalOpen.value = true;
      }
    },
  },
);

const [initiateSellResult, initiateSell, { retry: retryInitiateSell }] = useInitiateSell();
const loading = computed(() => isSubmitting.value || quoteResult.value.status === 'loading');

const onSubmit = handleSubmit(async () => {
  invariant(quoteResult.value.status === 'success');

  try {
    listenForErrorMessage(window, () => {
      errorMessage.value = t('widget.validation.server-error');
    });

    const response = await initiateSell({
      quoteId: quoteResult.value.data.quoteId,
      ref: parseRef(window.location),
    });

    redirectUrl.value = response.txnUrl;
    pushMessageForInitiateSell(window, response.txnUrl);

    redirectTimeout.handle();
  } catch {
    //handled in result
  }
});

watchDebounced(
  [() => values.cryptoAmount, () => values.cryptoCurrency, () => values.fiatCurrency],
  async (newValues, oldValues) => {
    const fields = await Promise.all([
      validateField('cryptoAmount'),
      validateField('cryptoCurrency'),
      validateField('fiatCurrency'),
    ]);
    if (!fields.every((f) => f.valid)) {
      return;
    }

    const cryptoCurrency = newValues[1];

    if (cryptoCurrency !== oldValues[1]) {
      setFieldValue(
        'cryptoAmount',
        config.DEFAULTS.defaultCryptoAmounts.filter((c) => c.crypto.toLowerCase() === cryptoCurrency.toLowerCase())[0]
          .value,
        false,
      );
    }

    errorMessage.value = '';
    try {
      await refetchQuote();
    } catch {
      //handled in result
    }
  },
  {
    debounce: 200,
  },
);

refetchQuote();

function isHandledQuoteError(result: QueryResult<TrafficProviderQuote>) {
  return (
    result.status === 'error' &&
    result.error instanceof ApiError &&
    (result.error?.details?.code === 'amount_too_high' ||
      result.error?.details?.code === 'amount_too_low' ||
      result.error?.details?.code === 'invalid_amount')
  );
}
</script>

<style scoped lang="postcss">
@import '../assets/breakpoints';

.widget {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .content-area {
    width: 100%;
    height: 100%;
    max-height: 448px;
    background-color: v-bind("theme['system-0']");
    display: flex;
    flex-direction: column;
    align-items: center;

    > .form {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      /* Fix for the ant select dropdown toggle element */
      :deep() :is(.sell, .get) .s-widget-input-money.is-invalid .input {
        color: v-bind("theme['warning-100']");
      }
      :deep() .sell .currency .ant-select-selector {
        cursor: pointer;
      }
      :deep() .s-form-message:not(.rate-message, .loading) .s-caption {
        color: v-bind("theme['warning-100']");
      }
    }

    > .bottom-content {
      width: 100%;
      padding: 16px;
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      > .call-to-actions {
        margin-top: 16px;
        width: 100%;

        > .sell-now-button,
        > .refresh-quote-button {
          width: 100%;
        }
        > .sell-now-link {
          --s-button-height: 48px;
          all: unset;
          box-sizing: border-box;
          height: var(--s-button-height);
          padding: 14px 24px;
          border-radius: calc(var(--s-button-height) / (100 / v-bind("theme['corner-50']")));
          text-align: center;
          text-transform: capitalize;
          color: v-bind("theme['primary-button-text']");
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          background-color: transparent;
          border: 1px solid v-bind("theme['brand-1-100']");
          color: v-bind("theme['brand-1-100']");

          &:focus {
            border: 2px solid v-bind("theme['brand-1-120']");
          }

          &:hover {
            background-color: v-bind("theme['brand-1-10']");
            border: 1px solid v-bind("theme['brand-1-100']");
          }

          &:active {
            background-color: v-bind("theme['brand-1-20']");
            border: 1px solid v-bind("theme['brand-1-100']");
          }
          svg {
            height: 18px;
            width: 18px;
          }

          .s-button-standard-typography {
            color: v-bind("theme['brand-1-100']");
            font-size: 14px;
            font-weight: 500;
            line-height: 16.41px;
          }

          @media (min-width: 500px) {
            .s-button-standard-typography {
              font-size: 16px;
              line-height: 18.75px;
            }
          }

          .margin-right {
            margin-right: v-bind("theme['readonly']['spacing']['spacing-1']");
          }
        }
      }

      > .support {
        margin-top: 16px;
      }
    }
  }
}
</style>
