/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  InitiateSell,
  InitiateSellResponse,
  ProblemDetails,
  TpQuotePaymentMethod,
  TrafficProviderQuote,
} from '../models/index';
import {
  InitiateSellFromJSON,
  InitiateSellToJSON,
  InitiateSellResponseFromJSON,
  InitiateSellResponseToJSON,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
  TpQuotePaymentMethodFromJSON,
  TpQuotePaymentMethodToJSON,
  TrafficProviderQuoteFromJSON,
  TrafficProviderQuoteToJSON,
} from '../models/index';

export interface InitiateSellWidgetRequest {
  initiateSell: InitiateSell;
  ref?: string;
}

export interface QuoteRequest {
  baseCurrency: string;
  quoteCurrency: string;
  baseAmount?: number;
  quoteAmount?: number;
  accountId?: string;
  ppPaymentMethod?: TpQuotePaymentMethod;
  ref?: string;
  lp?: string;
}

/**
 *
 */
export class ExternalApi extends runtime.BaseAPI {
  /**
   * A SellCrypto transaction is initiated by the entity representing the end-user, such as a wallet app, an exchange, etc. Each transaction has an identifier, which you use when referring to the transaction. This identifier is created by Simplex and returned in the response. Parameters except account_id in account_details are optional but allow Simplex’s risk algorithms to approve a broader range of end-users. The more information you supply in account_details the more accurate Simplex’s risk decisions will be, and as a result the happier your users become. The response includes a transaction URL to which you send your end-user in order to start the checkout flow. The payment method (simplex-account / credit-card) of the transaction decide by the payment_method sent on the quote
   * Create a SellCrypto transaction, and have the end-user start the checkout flow.
   */
  async initiateSellWidgetRaw(
    requestParameters: InitiateSellWidgetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InitiateSellResponse>> {
    if (requestParameters['initiateSell'] == null) {
      throw new runtime.RequiredError(
        'initiateSell',
        'Required parameter "initiateSell" was null or undefined when calling initiateSellWidget().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['ref'] != null) {
      queryParameters['ref'] = requestParameters['ref'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/v3/initiate-sell/widget`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InitiateSellToJSON(requestParameters['initiateSell']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InitiateSellResponseFromJSON(jsonValue));
  }

  /**
   * A SellCrypto transaction is initiated by the entity representing the end-user, such as a wallet app, an exchange, etc. Each transaction has an identifier, which you use when referring to the transaction. This identifier is created by Simplex and returned in the response. Parameters except account_id in account_details are optional but allow Simplex’s risk algorithms to approve a broader range of end-users. The more information you supply in account_details the more accurate Simplex’s risk decisions will be, and as a result the happier your users become. The response includes a transaction URL to which you send your end-user in order to start the checkout flow. The payment method (simplex-account / credit-card) of the transaction decide by the payment_method sent on the quote
   * Create a SellCrypto transaction, and have the end-user start the checkout flow.
   */
  async initiateSellWidget(
    requestParameters: InitiateSellWidgetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InitiateSellResponse> {
    const response = await this.initiateSellWidgetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * In order for you to provide the “Quote” experience described in the “Flow” section, where users make the decision of how much cryptocurrency they wish to sell, you need to be able to convert back and forth between crypto and fiat money. This is what the get-quote API is for.
   * Convert between crypto and fiat money.
   */
  async quoteRaw(
    requestParameters: QuoteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TrafficProviderQuote>> {
    if (requestParameters['baseCurrency'] == null) {
      throw new runtime.RequiredError(
        'baseCurrency',
        'Required parameter "baseCurrency" was null or undefined when calling quote().',
      );
    }

    if (requestParameters['quoteCurrency'] == null) {
      throw new runtime.RequiredError(
        'quoteCurrency',
        'Required parameter "quoteCurrency" was null or undefined when calling quote().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['baseCurrency'] != null) {
      queryParameters['base_currency'] = requestParameters['baseCurrency'];
    }

    if (requestParameters['baseAmount'] != null) {
      queryParameters['base_amount'] = requestParameters['baseAmount'];
    }

    if (requestParameters['quoteCurrency'] != null) {
      queryParameters['quote_currency'] = requestParameters['quoteCurrency'];
    }

    if (requestParameters['quoteAmount'] != null) {
      queryParameters['quote_amount'] = requestParameters['quoteAmount'];
    }

    if (requestParameters['accountId'] != null) {
      queryParameters['account_id'] = requestParameters['accountId'];
    }

    if (requestParameters['ppPaymentMethod'] != null) {
      queryParameters['pp_payment_method'] = requestParameters['ppPaymentMethod'];
    }

    if (requestParameters['ref'] != null) {
      queryParameters['ref'] = requestParameters['ref'];
    }

    if (requestParameters['lp'] != null) {
      queryParameters['lp'] = requestParameters['lp'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v3/quote`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TrafficProviderQuoteFromJSON(jsonValue));
  }

  /**
   * In order for you to provide the “Quote” experience described in the “Flow” section, where users make the decision of how much cryptocurrency they wish to sell, you need to be able to convert back and forth between crypto and fiat money. This is what the get-quote API is for.
   * Convert between crypto and fiat money.
   */
  async quote(
    requestParameters: QuoteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TrafficProviderQuote> {
    const response = await this.quoteRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
