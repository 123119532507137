import { useMutation as useVueQueryMutation } from '@tanstack/vue-query';
import { computed, ref } from 'vue';
import { invariant } from '@simplex/rnd.invariant';
import type { IWidgetApi } from './api';
import { withApiError } from './errors';
import type { DeferredResult } from './results';
import { useApi } from './use-api';

export function useMutation<T, TArgs>(
  key: string,
  fn: (client: IWidgetApi, args: TArgs) => Promise<T>,
  options?: { onSuccess?: (data: T) => void | Promise<void> },
) {
  const api = useApi();
  const _args = ref<TArgs>();

  const { data, error, status, mutateAsync } = useVueQueryMutation<T, Error, TArgs, unknown>({
    mutationKey: [key],
    mutationFn: (args) => withApiError(api, args, fn),
    onSuccess: options?.onSuccess,
  });

  const invoke = (args: TArgs) => {
    _args.value = args;
    return mutateAsync(args);
  };

  return [
    computed<DeferredResult<T>>(() => {
      if (status.value === 'idle') {
        return {
          status: 'init' as const,
        };
      }

      if (status.value === 'pending') {
        return {
          status: 'loading' as const,
        };
      }

      if (status.value === 'error') {
        return {
          status: 'error' as const,
          error: error.value as Error,
        };
      }

      return {
        status: 'success' as const,
        data: data.value as T,
      };
    }),
    invoke,
    {
      retry: () => {
        invariant(_args.value, 'mutation should be called first');
        return mutateAsync(_args.value);
      },
    },
  ] as const;
}
